.forum-user {
    &.font-size-small {
        font-size: 10px;
    }
    &.font-size-medium {
        font-size: 14px;
    }
    &.font-size-large {
        font-size: 20px;
    }
    &.font-size-super-large {
        font-size: 30px;
    }
}
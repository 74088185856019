@import "partials/buttons";
@import "partials/forumUser";

html {
    // background: linear-gradient(0deg, #003084 50%, #CDE 50%);
    // background-size: 120% 173px;
    // background-repeat: no-repeat;
    // import header_background from "../../assets/images/header_background.png";

    // background-image: url(assets/images/header_background.png);
    // background-repeat: repeat-x;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.top-table {
    border-spacing: 0 !important;
    //border-color: transparent !important;
    position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
    width: calc(100% - 2px);
    min-width: calc(100% - 2px);
    .center-td {
        background-color: #f5faFF;
    }
}

.display-none {
    display: none;
}

.display-block {
    display: block;
}

blockquote {
    background-color: rgba(255, 255, 255, 0.3);
    display: table;
    padding: 5px 7px;
    border: 1px solid #aaa;
    box-shadow: 3px 3px 3px 1px rgba(0,0,0,0.1);
    border-spacing: 5px;
}

.content-wrap {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: stretch;
}
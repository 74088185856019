.generic-paging {
    display: flex;
    align-self: flex-end;
    user-select: none;
    white-space: nowrap;
    span {
        font-size: 120%;
        color: Blue;
        text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
        margin-right: 2px;
    }
    div:nth-child(1) {
        align-self: flex-end;
        padding: 0 0 3px 0px;
        font-size: 140%;
    }

    .page-buttons {
        display: flex;
        margin-bottom: 4px;
        margin-left: 10px;
        border-collapse: collapse;
        .page-button {
            font-size: 140%;
            height: 31px;
            padding: 5px 5px 0 5px;

            min-width: 32px;
            text-align: center;
            border: 0.01rem solid black;
            align-self: flex-end;
            color: blue;
            cursor: pointer;
            &.arrow {
                min-width: 28px;
            }
            &:hover {
                background: yellow;
            }
            &.active {
                background: cyan;
            }
            &.noop {
                cursor: not-allowed;
                &:hover {
                    background-color: #eee;
                }
            }
            span {
                position: relative;
                top: -2px;
                &.span_windows {
                    top: -5px;
                }
            }
        }
        .page-dot {
            font-size: 200%;
            align-self: flex-end;
            margin: 0 5px;
        }
    }


    .items-per-page {
        margin-top: 5px;
        margin-left: 20px;
        font-size: 130%;
        input[type="text"] {
            position: relative;
            margin: 0 3px 0 6px;
            font-size: 100%;
            width: 30px;
            padding: 3px 5px 0px 5px;
            text-align: center;
        }
    }
    .low-items-spacer {
        width: 10px;
    }
}

.admin {
  margin-left: 20px;
  margin-top: 6px;
  border: 1px solid blue;
  background-color: #bbddff;
  padding: 1px 3px;
  font-size: 120%;
  &.delete-post {
      position: relative;
      top: -0.25rem;
      cursor: pointer;
      display: inline-block;
      div {
      &:hover {
          color: red;
      }
  }
  }
  .add-news-success {
      color: green;
      font-size: 80%;
  }
  > div:first-child {
      cursor: pointer;
      font-size: 60%;
      align-self: flex-end;
  }
  .admin-form {
      position: relative;
      table {
          width: 100%;
          tr:first-child {
              td:first-child {
                  font-weight: bold;
              }
          }
          td:first-child {
              text-align: right;
          }
          tr.imageTr {
              td {
                  padding-top: 1px;
              }
          }
      }
      input[type="text"] {
          width: 100%;
          font-size: 110%;
          padding: 0 2px;
          box-sizing: border-box;
      }
      input[type="file"] {
          //width: 80%;
          margin-left: 5px;
          font-size: 90%;
      }
      .rounded-button {
          float: right;
      }
      .textarea-headline {
          width: 800px;
          height: 58px;
          font-size: 110%;
          padding: 2px 4px;
          font-family: Arial;
          box-sizing: border-box;
      }
      .textarea-snippet {
          width: 800px;
          height: 200px;
          font-size: 110%;
          padding: 2px 4px;
          font-family: Arial;
          box-sizing: border-box;
      }
      td {
          vertical-align: top;
          img {
              float: left;
              max-width: 125px;
          }
      }
      .close-x {
          font-size: 90%;
          color: rgba(255, 0, 0, 0.5);
          background-color: rgba(255, 255, 255, 0.3);
          border: 0.01rem solid rgba(0, 0, 0, 0.3);
          transform: scale(1.3, 1);
          position: absolute;
          right: 3px;
          top: 2px;
          padding: 0 2px;
          box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
          cursor: pointer;
          &:hover {
              color: red;
              background-color: rgba(255, 255, 255, 0.5);
          }
      }
  }
}
.news-item {
  display: flex;
  font-size: 90%;
  box-shadow: 1px 1px 2px 2px rgba(0,0,0,0.1);

  @media screen and (min-width: 1180px) {
    font-size: 100%;
}
@media screen and (min-width: 1300px) {
    font-size: 120%;
}
@media screen and (min-width: 1500px) {
    font-size: 130%;
}

  flex-grow: 1;
  .left-block {
    font-size: 90%;
    flex: 0 0 141px;
    padding: 0 5px;
    display: flex;
    background-color: #cce;
    border-right: 1px solid #444;
    box-sizing: border-box;
    .comment {
      margin: auto; /* Important */
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  .right-block {
    color: black;
    flex-grow: 1;
    padding: 6px 12px 7px 12px;
    display: flex;

    .image-side {
      min-width: 130px;
    //   background-color: #001a4a;
    //   border: 5px solid #000;
      display: inline-block;
      padding: 0.5em 0.5em;

      border-radius: 0.5em;

      .news-image {
        height: 70px;
        text-align: center;
        position: relative;

        .cover {
          position: absolute;
          left: 0;
          top: 0;
          width: 130px;
          height: 70px;
          z-index: 1;
          //background: linear-gradient(to right, rgba(0, 0, 80, 0.85), 20%, #333, rgba(0, 0, 80, 0.85));
        }
        img {
          position: relative;
          z-index: 2;
          height: 70px;
          width: auto;
          opacity: 1;
          max-width: 130px;
        }
      }
    }
    .text-side {
      margin-left: 10px;
      margin-top: 6px;
      width: 100%;
      .top-line {
        display: flex;
        justify-content: space-between;
        .views-likes {
            display: flex;
            height: 25px;
            padding: 2px 1px 1px 1px;
            border: 1px solid rgba(0,0,0,0.2);
            box-sizing: border-box;
            margin-left: 10px;
            font-size: 90%;
            background-color: rgba(0,100,30,0.08);
            box-shadow: inset 1px 1px 1px 1px rgba(0,50,0,0.2), inset -2px -1px 1px 1px rgba(255,255,255,0.5);
            .label {
                color: #777;
                margin: 0 2px 0 3px;
                &.clickable {
                    cursor: pointer;
                    color: blue;
                    text-shadow: 0 0 blue;
                }
                &.user-liked {
                    color: #777;
                    text-shadow: 0 0 transparent;
                }
            }
            .news-views-number {
                color: blue;
                margin-left: 2px;
                border: 1px solid rgba(0,0,0,0.2);
                background-color: rgba(255,255,255,0.5);
                padding: 0 2px;
                span {
                    position: relative;
                    top: -1px;
                }
            }
            :nth-child(2) {
                margin-right: 10px;
            }
            :nth-child(4) {
                margin-right: px;
            }
        }
        .source {
          display: inline-block;
          color: red;
          margin: 0 6px 0 4px;
        }
        .title {
          display: inline-block;
          font-weight: bold;
          overflow: hidden;
          min-width: 40%;
          margin-right: 5px;
          color: blue;
          font-size: 110%;
          a {
            text-decoration: none;
          }
        }
        .published-date {
          display: inline-block;
          white-space: nowrap;
        }
      }
      .snippet {
        margin-top: 6px;
      }
    }

  }
}

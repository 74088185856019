.home-forum {
    width: 200px;
    padding: 5px;
    display: none;
    @media screen and (min-width: 1180px) {
        display: block;
    }
    @media screen and (min-width: 1300px) {
        font-size: 110%;
        width: 225px;
    }
    @media screen and (min-width: 1500px) {
        font-size: 120%;
        width: 250px;
    }
    > div:first-child {
        font-size: 120%;
    }
    .topic-section {
        border: 1px solid #eee;
        padding: 4px 0;

        a {
            text-decoration: none;
            &:hover {
                text-decoration: underline;
                color: #d00;
            }
        }
        .topic-title {
            font-size: 100%;
        }
        .subtopic-title {
            font-size: 90%;
            margin-left: 20px;
        }
        .posts-count {
            color: #c11;
            position: relative;
            left: 16px;
        }
    }
}

body {
    overflow: hidden;
}
.home {
    padding-top: 120px;
    display: flex;
    justify-content: center;
    background-color: #f3f3f0;
    height: 90vh;
    overflow: hidden;
    .book-deal {
        background: #ddffff;
        padding: 5px;
        border: 1px solid #577;
        margin: 10px;
        justify-content: center;
        display: flex;
        font-size: 150%;
        >div {
            align-self: center;
            padding: 0 20px;
        }
        a {
            font-size: 70%;
            text-decoration: none;
        }
    }
    .forum-section {
        border: 1px solid #666;
        margin-left: 4px;
        display: none;
        @media screen and (min-width: 1180px) {
            display: block;
        }
    }
    .blogs-section {
        border: 1px solid #666;
        margin-right: 4px;
        display: none;
        @media screen and (min-width: 1180px) {
            display: block;
        }
    }
    .news-section {
        
        >.section-title {
            display: flex;
            font-size: 140%;
            margin-left: 1vw;
            .admin-container {
                position: relative;
                top: -2px;
            }
            @media screen and (min-width: 1180px) {
                font-size: 150%;
                .admin-container {
                    position: relative;
                    top: -3px;
                }
            }
            @media screen and (min-width: 1300px) {
                font-size: 160%;
            }
            @media screen and (min-width: 1500px) {
                font-size: 170%;
                margin-left: 20px;
            }

        }

    }
}
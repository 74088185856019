button,
input[type="button"],
input[type="submit"] {
    position: relative;
    font-size: 18px;
    height: 28px;
    border: 1px solid #68d;
    color: white;
    font-weight: bold;
    padding: 1px 10px 1px 10px;
    border-radius: 10px;
    box-shadow: 0pxs 0px 1px 1px rgba(0, 0, 0, 1), 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    &.opacity-zero {
        opacity: 0;
    }
    &:active {
        border: 1px solid #f00;
    }
    &:focus {
        outline: none;
    }

    &.positive-action {
        background: radial-gradient(circle at 50% 50%, #22c 0%, #1616b6 50%, #11b 75%, #00a 100%);
        &:hover {
            color: yellow;
            background: radial-gradient(circle at 50% 50%, #34e 0%, #1a1ed6 50%, #12d 75%, #01c 100%);
        }
    }

    &.neutral-action {
        border: 1px solid #000;
        background: radial-gradient(circle at 50% 50%, #ff4 0%, #f6f626 50%, #ff3 75%, #ff4 100%);
        color: black;
        &:hover {
            background: radial-gradient(circle at 50% 50%, #ff9 0%, #fafa2a 50%, #ff9 75%, #ffc 100%);
        }
    }
}

div.simple-button {
    position: relative;
    z-index: 3;
    padding: 2px 5px 2px 5px;
    &.windows {
        padding: 0px 5px 4px 5px;
    }
    font-weight: bold;
    background-color: #f5f500;
    color: black;
    border: 1px solid black;
    display: inline-block;
    cursor: pointer;
    user-select: none;
    height: 20px;
    box-shadow: inset -1px -1px 1px 2px rgba(0, 0, 0, 0.1), inset 1px 1px 1px 2px rgba(255, 255, 255, 0.3);

    &.small {
        font-size: 14px;
        font-weight: normal;
        padding: 1px 6px;
        height: 16px;
        color: #333;
        &.windows {
            padding: 0px 6px 2px 6px;
        }
    }
    &:active {
        box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.3);
    }
    &:hover {
        background-color: #ff4;
    }
    &.blue {
        background-color: #9df;
        &:hover {
            background-color: #aef;
        }
    }
    &.orange {
        background-color: #fa0;
        &:hover {
            background-color: #fc5;
        }
    }
    &.red {
        background-color: #f00;
        color: white;
        &:hover {
            background-color: #f55;
        }
    }
    &.hide {
        display: none;
    }
    &.show {
        display: inline-block;
    }
    &.inactive {
       background-color: #ddd;
       color: #888;
       opacity: 0.5;
       cursor: default;
       border: 1px solid #555;
    }
}

.rounded-button {
    cursor: pointer;
    padding: 4px 15px;
    color: white;
    border-radius: 20px;
    background-image: linear-gradient(#6677ff, #5566ff, #3355aa);
    &:hover {
        color: rgb(140, 255, 255);
        background-image: linear-gradient(#5566ee, #4455ee, #23a);
    }
}

div.action-button {
    padding: 2px 5px 2px 5px;
    @-moz-document url-prefix() {
        padding: 4px 5px 0 5px;
    }
    &.windows {
        padding: 1px 5px 3px 5px;
    }
    font-weight: bold;
    background-color: #9ee;
    color: black;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 92%;
    margin: 0 3px;

    &.blue {
        background-color: #bff;
        &:hover {
            background-color: #dff;
        }
    }
    &.yellow {
        background-color: #f6f690;
        &:hover {
            background-color: #ffa;
        }
    }

    span {
        font-size: 90%;
        line-height: 100%;
    }
    &:hover {
        cursor: pointer;
        color: red;
        text-shadow: 0 0 red;
    }
    &.show {
        display: inline-block;
    }
}

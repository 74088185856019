.generic-search {
    display: flex;
    font-size: 130%;
    align-self: flex-end;
    white-space: nowrap;
    position: relative;
    top: -4px;
    > span {
        font-size: 110%;
        position: relative;
        top: 1px;
    }
    input[type="text"] {
        font-size: 90%;
        margin: 0 8px;
        padding: 0 3px;
    }
}
.news {
    padding: 10px;
    .top-line {
        display: flex;
        > div:nth-child(2) {
            position: relative;
            top: 12px;
            padding-left: 20px;
        }

    }
    @media screen and (max-width: 1500px) {
        .top-line {
            display: block;
            > div:nth-child(2) {
                position: static;
                padding-top: 12px;
                padding-left: 2px;
            }
        }
    }
    .news-item-rows {
        > div {
            //border: 1px solid rgba(0, 0, 0, 0.4);
            background-color: #999;
            padding: 1px 1px;
            margin: 3px 0;
            display: flex;
            &:nth-child(odd) {
                .right-block {
                    background-color: #fefefe;
                }
            }
            &:nth-child(even) {
                .right-block {
                    background-color: #f9f9f9;
                }
            }
        }
    }
}

.blogs {
    width: 200px;
    padding: 5px 4px;
    background: rgba(100,200,255,0.1);
    height: 100%;
    .blogs-title {
        font-size: 130%;
    }
    a {
        text-decoration: none;
        color: blue;
    }
    .blogger-add-blog {
        border: 1px solid black;
        background-color: #55eeff;
        margin: 5px 5px 5px 5px;
        padding: 2px 0;
        border-radius: 3px;
        text-align: center;
        cursor: pointer;
    }


    .blog-row {
        border: 1px solid black;
        //padding: 3px 3px 3px 3px;
        margin: 6px 0 5px 1px;
        border-radius: 3px;
        &:hover {
            border: 1px solid #e00;
        }
        .blog-meta {
            background: rgba(100,200,255,0.2);
            display: flex;
            justify-content: space-between;
            padding: 2px 2px 2px 3px;
            border-bottom: 1px solid #4af;
            span:first-child {
                color: blue;
            }
            span:nth-child(2) {
                color: #000;
                font-size: 90%;
            }
        }
        .blogTitle {
            text-shadow: 0 0 rgba(0,0,0,0.3);
            font-size: 100%;
            padding: 3px 3px 4px 3px;
        }
    }
}